import { memo, useEffect, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { css } from '@emotion/react';
import { FormControl, IconButton, MenuItem, Select, ToggleButton, ToggleButtonGroup, InputLabel, } from '@mui/material';
import uniq from 'lodash/uniq';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
import dayAPI from '~/utils/dayAPI';
import { tradeAPI } from '~/modules/SDK/Trade/tradeAPI';
import { globalGrey } from '~/modules/AppLayout/Colors';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import DateRangePicker from '~/components/DateRangePicker';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
import { AuthTradeAPI } from '~/modules/SDK/Trade/AuthTradeAPI';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
const headerCss = css `
  padding: 16px;
  background: ${globalGrey.g100};
  ${flex.h.crossCenter} & > * {
    margin: 0;
    margin-right: 16px !important;
  }
`;
const StatementHeader = memo(function StatementHeader() {
    const { state } = useVirtualExchangeCenterParametersState.useContainer();
    const currentSelectAccountId = useTradeStore(s => s.currentSelectAccountId);
    const currentStrategyName = useTradeStore(s => s.currentStrategyName);
    const statements = useTradeStore(s => s.statements);
    const [beginDatetime, setBeginDatetime] = useState(dayAPI().add(-7, 'day').format('YYYY-MM-DD'));
    const [endDatetime, setEndDatetime] = useState(dayAPI().format('YYYY-MM-DD'));
    /** 搭配 symbol 才有用 */
    const [limit, setLimit] = useState(10);
    const [orderKey, setOrderKey] = useState('asc');
    const [symbol, setSymbol] = useState('ALL');
    const isUserQualified = state.isUserQualified;
    const handleBeginDate = (formattedDate) => {
        if (formattedDate !== 'Invalid Date')
            setBeginDatetime(formattedDate);
    };
    const handleEndDate = (formattedDate) => {
        if (formattedDate !== 'Invalid Date')
            setEndDatetime(formattedDate);
    };
    const isStrategyCenter = fr_agents.is['futuresai@strategy_center'];
    const handleForceUpdate = () => {
        const options = {
            accountId: currentSelectAccountId,
            beginDatetime,
            endDatetime,
            symbol: symbol === 'ALL' ? undefined : symbol,
            limit: symbol === 'ALL' ? undefined : limit,
            orderBy: orderKey,
        };
        if (isStrategyCenter && currentStrategyName)
            AuthTradeAPI.getStatements(options);
        else if (!isStrategyCenter && currentSelectAccountId)
            tradeAPI.getStatements(options, isUserQualified ? false : true);
    };
    useEffect(() => {
        const options = {
            accountId: currentSelectAccountId,
            beginDatetime,
            endDatetime,
            symbol: symbol === 'ALL' ? undefined : symbol,
            limit: symbol === 'ALL' ? undefined : limit,
            orderBy: orderKey,
        };
        if (isStrategyCenter && currentStrategyName)
            AuthTradeAPI.getStatements(options);
        else if (!isStrategyCenter && currentSelectAccountId)
            tradeAPI.getStatements(options, isUserQualified ? false : true);
    }, [currentSelectAccountId, limit, beginDatetime, endDatetime, symbol, orderKey, isUserQualified]);
    const symbolSelectList = uniq(statements.map(s => s.symbol));
    return (<div css={headerCss}>
      <DateRangePicker containerCss={css `
          & > * {
            margin-right: 16px !important;
          }
        `} start={dayAPI(beginDatetime)} end={dayAPI(endDatetime)} startChangeDelegate={(value) => {
            if (value)
                handleBeginDate(value.startOf('day').format('YYYY-MM-DD'));
        }} endChangeDelegate={(value) => {
            if (value)
                handleEndDate(value.endOf('day').format('YYYY-MM-DD'));
        }} startLabel='平倉開始' endLabel='平倉結束'/>

      <ToggleButtonGroup size='small'>
        <ToggleButton value={'asc'} onClick={() => setOrderKey('asc')} selected={'asc' === orderKey}>
          asc
        </ToggleButton>
        <ToggleButton value={'desc'} onClick={() => setOrderKey('desc')} selected={'desc' === orderKey}>
          desc
        </ToggleButton>
      </ToggleButtonGroup>
      <FormControl css={css `
          width: 280px;
        `}>
        <InputLabel>選擇特定商品</InputLabel>
        <Select label='選擇特定商品' disabled={symbolSelectList.length === 0} value={symbol} onChange={e => {
            const { value } = e.target;
            setSymbol(value);
        }}>
          <MenuItem key={-1} value={'ALL'}>
            全部
          </MenuItem>
          {symbolSelectList.map(s => (<MenuItem key={s} value={s}>
              <div css={flex.h.crossCenter}>
                {s}&nbsp;
                <SymbolName symbol={s}/>
              </div>
            </MenuItem>))}
        </Select>
      </FormControl>
      {symbol === 'ALL' ? null : (<FormControl css={css `
            width: 120px;
          `}>
          <InputLabel>數量</InputLabel>
          <Select label='數量' value={limit} onChange={e => {
                const { value } = e.target;
                setLimit(value);
            }}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>)}
      <IconButton color='error' onClick={handleForceUpdate}>
        <MdRefresh />
      </IconButton>
    </div>);
});
export default StatementHeader;
