import { memo, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayAPI from '~/utils/dayAPI';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
import { tradeAPI } from '~/modules/SDK/Trade/tradeAPI';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import DateRangePicker from '~/components/DateRangePicker';
import DailyPerformanceChart from '~/modules/tradeSheet/tradingAnalyzer/DailyPerformanceChart';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
import { AuthTradeAPI } from '~/modules/SDK/Trade/AuthTradeAPI';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
const DailyPnlContent = memo(function DailyPnlContent() {
    const { state: { isUserQualified }, } = useVirtualExchangeCenterParametersState.useContainer();
    const currentSelectAccountId = useTradeStore(s => s.currentSelectAccountId);
    const [begin, setBegin] = useState(dayAPI().add(-3, 'month'));
    const [end, setEnd] = useState(dayAPI());
    const isStrategyCenter = fr_agents.is['futuresai@strategy_center'];
    useEffect(() => {
        if (begin.isValid() && end.isValid()) {
            if (isStrategyCenter)
                AuthTradeAPI.getDailyProfit(begin.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
            else if (currentSelectAccountId)
                tradeAPI.getDailyProfit(isUserQualified ? false : true, begin.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
        }
    }, [currentSelectAccountId, isUserQualified, begin, end]);
    const dailyProfit = useTradeStore(state => state.dailyProfit);
    const data = useMemo(() => {
        return (dailyProfit.map(datum => ({
            datetime: dayAPI(datum.datetime).format('YYYY/MM/DD'),
            profit: datum.dailyRealizedPnL,
        })) ?? []);
    }, [dailyProfit]);
    const headerCss = css `
    ${flex.h.allCenter};
    & > * {
      margin-right: 16px !important;
      margin-bottom: 16px;
    }
  `;
    return (<div css={css `
        padding: 16px;
      `}>
      <div css={flex.h.crossCenter}>
        <DateRangePicker containerCss={headerCss} start={begin} end={end} startChangeDelegate={(value) => {
            if (value)
                setBegin(value.startOf('day'));
        }} endChangeDelegate={(value) => {
            if (value)
                setEnd(value.endOf('day'));
        }}/>
      </div>
      <div css={css `
          width: 600px;
          height: 400px;
        `}>
        <DailyPerformanceChart data={data}/>
      </div>
    </div>);
});
export default DailyPnlContent;
