import { css } from '@emotion/react';
import { Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow, tableRowClasses, } from '@mui/material';
import { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { fontWeight600 } from '~/css/font';
import { globalOrange } from '~/modules/AppLayout/Colors';
const OptionStrikePriceTable = memo(function OptionStrikePriceTable(props) {
    const data = props.data;
    if (isEmpty(data))
        return null;
    return (<Table stickyHeader css={css `
        ${smallTableCss};
        .${tableRowClasses.root}:nth-of-type(${props.atTheMoneyIndex + 1}) {
          background: ${globalOrange.o100};
        }
      `}>
      <TableHead>
        <TableRow>
          <TableCell>履約價格</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map(datum => (<Item_ key={datum} datum={datum}/>))}
      </TableBody>
    </Table>);
});
const smallTableCss = css `
  .${tableCellClasses.root}.${tableCellClasses.head} {
    text-align: center;
    padding: 0px;
  }
  .${tableCellClasses.root}.${tableCellClasses.body} {
    text-align: center;
    padding: 2px 8px;
    ${fontWeight600};
  }
  margin: 8px 0 32px;
`;
export const Item_ = memo(function Item_({ datum }) {
    return (<TableRow>
      <TableCell>{datum}</TableCell>
    </TableRow>);
});
export default OptionStrikePriceTable;
