import { css } from '@emotion/react';
import React, { memo, useEffect, useRef } from 'react';
import { useOpbsProcessingState } from '~/modules/options/shareContainers/useOpbsProcessingState';
import OptionStrikePriceTable from '~/modules/options/sharedComponents/new/OptionStrikePriceTable';
import OptionDetailsTable from '~/modules/options/sharedComponents/new/OptionDetailsTable';
import { useScroll, usePrevious } from 'react-use';
import { pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
const opbsInnerContainerCss = css `
  flex-grow: 1;
  ${pureGrid};
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
`;
const cpSumCss = css `
  width: 80px;
`;
const chartContainerCss = css `
  flex-grow: 1;
  min-width: 0;
  z-index: 4;
`;
/** 選擇權即時之報價表格 */
const OptionPriceComposedTable = memo(function OptionPriceComposedTable() {
    const { state, properties } = useOpbsProcessingState.useContainer();
    const { acts: { handleOpenForm }, } = useVirtualExchangeCenterParametersState.useContainer();
    const opbsOuterContainerCss = css `
    width: 100%;
    height: 100%;
    overflow-y: auto;
  `;
    const tableRef = useRef(null);
    const { y } = useScroll(tableRef);
    const prevY = usePrevious(y);
    useEffect(() => {
        if (y !== prevY) {
            handleOpenForm(undefined, '');
        }
    }, [y, prevY, handleOpenForm]);
    return (<div css={opbsOuterContainerCss} ref={tableRef}>
      <div css={opbsInnerContainerCss}>
        <div css={chartContainerCss}>
          <OptionDetailsTable data={state.callData} atTheMoneyIndex={state.atTheMoneyIndex}/>
        </div>
        <div css={cpSumCss}>
          <OptionStrikePriceTable data={properties.strikePrices} atTheMoneyIndex={state.atTheMoneyIndex}/>
        </div>
        <div css={chartContainerCss}>
          <OptionDetailsTable isMirror={true} data={state.putData} atTheMoneyIndex={state.atTheMoneyIndex}/>
        </div>
      </div>
    </div>);
});
export default OptionPriceComposedTable;
