// mui 提供的要$$
import { memo } from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
const DateRangePicker = memo(function DateRangePicker({ start, end, startChangeDelegate, endChangeDelegate, startLabel, endLabel, containerCss, }) {
    return (<div css={containerCss}>
      <DatePicker label={startLabel ?? '開始'} value={start} mask='____/__/__' inputFormat='YYYY/MM/DD' renderInput={params => (<TextField variant='standard' size='small' {...params}/>)} onChange={startChangeDelegate}/>
      <DatePicker label={endLabel ?? '結束'} value={end} inputFormat='YYYY/MM/DD' mask='____/__/__' renderInput={params => (<TextField variant='standard' size='small' {...params}/>)} onChange={endChangeDelegate}/>
    </div>);
});
export default DateRangePicker;
