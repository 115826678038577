import React, { memo } from 'react';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
import { FuturesWrapper } from '~/modules/virtual-exchange-center/components/Wrapper/Futures';
import { OpbsWrapper } from '~/modules/virtual-exchange-center/components/Wrapper/Opbs';
import { StockWrapper } from '~/modules/virtual-exchange-center/components/Wrapper/Stocks';
import { SelfWrapper } from '~/modules/virtual-exchange-center/components/Wrapper/Self';
const ModuleWrapper = memo(function ModuleWrapper() {
    const { state } = useVirtualExchangeCenterParametersState.useContainer();
    return (<>
      {state.module === 'opbs' && <OpbsWrapper />}
      {state.module === 'stock' && <StockWrapper />}
      {state.module === 'osfutures' && <FuturesWrapper />}
      {state.module === 'self' && <SelfWrapper />}
    </>);
});
export default ModuleWrapper;
