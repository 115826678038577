/**
 * 找出陣列中，總和最小的連續組合
 *
 * 使用情境：最大回測數值，以便得知過往交易紀錄中「淨值最低」的風險到哪裡
 */
export const perfGetMaxDrawDown = (numbers) => {
    let res = numbers[0];
    let curr = numbers[0];
    for (let i = 1; i < numbers.length; i++) {
        curr += numbers[i];
        if (curr > 0 || numbers[i] < curr)
            curr = numbers[i];
        if (res > curr)
            res = curr;
    }
    return res;
};
