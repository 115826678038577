import { useEffect, useState } from 'react';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import { verticalSpreads, generalStrategies, } from '~/modules/virtual-exchange-center/utils/opbsStrategyConstants';
const ALL_STRATEGIES = [
    { key: generalStrategies.STRADDLE, chinese: '跨式', tags: [] },
    { key: generalStrategies.STRANGLE, chinese: '勒式', tags: [] },
    { key: generalStrategies.CALL_SPREAD, chinese: '買權價差', tags: [] },
    { key: generalStrategies.PUT_SPREAD, chinese: '賣權價差', tags: [] },
    { key: generalStrategies.REVERSAL_CONVERSION, chinese: '逆轉轉換', tags: [] },
];
const useOpbsStrategyLogic = () => {
    const [strategy, setStrategy] = useState('STRADDLE');
    /** 勒式策略 */
    const [strangleItem, setStrangleItem] = useState('');
    /** 跨式策略 */
    const [straddleItem, setStraddleItem] = useState('');
    /** 逆轉、轉換策略 */
    const [reversalConversionItem, setReversalConversionItem] = useState('');
    /** 價差策略 */
    const [pair, setPair] = useState('');
    useEffect(() => {
        setPair('');
        setStrangleItem('');
        setStraddleItem('');
        setReversalConversionItem('');
    }, [strategy]);
    const isStraddleStrategy = strategy === 'STRADDLE';
    const isStrangleStrategy = strategy === 'STRANGLE';
    const isVerticalSpreadStrategy = verticalSpreads.includes(strategy);
    const isReversalConversionStrategy = strategy === 'REVERSAL_CONVERSION';
    return {
        state: {
            strategy,
            isStraddleStrategy,
            isStrangleStrategy,
            isVerticalSpreadStrategy,
            isReversalConversionStrategy,
            strangleItem,
            straddleItem,
            reversalConversionItem,
            pair,
        },
        props: { ALL_STRATEGIES },
        acts: { setStrategy, setStrangleItem, setStraddleItem, setReversalConversionItem, setPair },
    };
};
export const useOpbsStrategyLogicState = createContainer(useOpbsStrategyLogic);
