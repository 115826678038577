import { useMemo } from 'react';
import zipWith from 'lodash/zipWith';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import dayjs from 'dayjs';
import { useOptionContractCodeState } from '~/modules/options/shareContainers/useOptionContractCodeState';
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState';
import { useOpenInterestState } from '~/modules/options/shareContainers/useOpenInterestState';
/* modules/options/utils */
import getTimeValue from '~/modules/options/utils/getTimeValue';
import getSymbolPrice from '~/modules/options/utils/getSymbolPrice';
import { getOptionSymbol } from '~/modules/options/utils/getOptionSymbolUtil';
import { useParseOptionsContractMonthString } from '~/modules/options/utils/useParseOptionsContractMonthString';
import getAtThePriceAvgTimeValue from '~/modules/options/utils/getAtThePriceAvgTimeValue';
import { useSyntheticIndexStore } from './useSyntheticIndexStore';
/**
 * The data for OptionPriceNextPage and OptionCompareNextPage which need to be displayed
 *
 * 相較於 useOpbsCombineState，此 Provider 不需要call https://api.futures-op.com/api/v2/opbs
 *
 * 以此區分
 */
export const useOpbsProcessing = () => {
    const { state: contractCodeState } = useOptionContractCodeState.useContainer();
    const { state: { currentContractOi }, } = useOpenInterestState.useContainer();
    const contract_ = useParseOptionsContractMonthString(contractCodeState.month);
    const isMonth = contract_?.contractType === 'month';
    const { state: { callSource, putSource }, } = useOptionsSourceState.useContainer();
    const currentYear = contractCodeState.month?.substring(0, 4) ?? dayjs().year().toString();
    /**
     * 合併所有買權、賣權之後，再取得五碼的履約價，
     *
     * WHY ? 16500C 不代表有相對應的 16500P
     *
     * 降冪排列：[18150, 18100, 18050, ...]
     */
    const strikePrices = callSource
        .concat(putSource)
        .map(socketDatum => getSymbolPrice(socketDatum?.symbol))
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => b - a);
    let callData = strikePrices.map(price => {
        const matchOne = callSource.find(s => getMatchQuote(s?.symbol, price));
        const oi = currentContractOi?.data.find(datum => getOpenInterest(datum, price, 'c'))?.oi;
        const callSocket = matchOne
            ? {
                ...matchOne,
                openInterest: oi,
            }
            : { ...emptySocketDataValue };
        callSocket.symbol = getOptionSymbol(isMonth, contract_, 'C', price, currentYear);
        return callSocket;
    });
    let putData = strikePrices.map(price => {
        const matchOne = putSource.find(s => getMatchQuote(s?.symbol, price));
        const oi = currentContractOi?.data.find(datum => getOpenInterest(datum, price, 'p'))?.oi;
        const putSocket = matchOne
            ? {
                ...matchOne,
                openInterest: oi,
            }
            : { ...emptySocketDataValue };
        putSocket.symbol = getOptionSymbol(isMonth, contract_, 'P', price, currentYear);
        return putSocket;
    });
    // 取得 call 與對應的 put，兩者的現價差
    const callPutDiff = useMemo(() => {
        const diff = zipWith(callData.map(x => x.close), putData.map(x => x.close), (cPrice, pPrice) => (cPrice === 0 || pPrice === 0 ? 10000 : Math.abs(cPrice - pPrice)));
        return diff.filter(d => d);
    }, [callData, putData]);
    /** 取得價平點 */
    const atTheMoneyIndex = callPutDiff.indexOf(Math.min(...callPutDiff));
    const atTheMoneyPrice = getSymbolPrice(callData[atTheMoneyIndex]?.symbol);
    const syntheticPrice = atTheMoneyPrice + callData[atTheMoneyIndex]?.close - putData[atTheMoneyIndex]?.close;
    /** 計算時間價值 */
    callData = callData.map(c => getTimeValue(c, syntheticPrice, true));
    putData = putData.map(p => getTimeValue(p, syntheticPrice, false));
    /** 價平時間價值 */
    const atThePriceAvgTimeValue = getAtThePriceAvgTimeValue(callData, putData, syntheticPrice, atTheMoneyIndex);
    useSyntheticIndexStore.syntheticIndexPrice = syntheticPrice;
    useSyntheticIndexStore.atThePriceAvgTimeValue = atThePriceAvgTimeValue;
    return {
        state: {
            atTheMoneyIndex,
            atTheMoneyPrice,
            callData,
            putData,
        },
        properties: {
            strikePrices,
        },
    };
};
/* 比對該 datum 是否為我們要找的，用履約價 及 是買權 or 賣權，回傳 boolean */
export const getOpenInterest = (datum, price, type) => datum.cp === type && datum.strike_price === price;
/* 比對選擇權商品的履約價，與傳入的 price 有無相符，回傳 boolean  */
export const getMatchQuote = (symbol, price) => symbol ? getSymbolPrice(symbol) === price : false;
const emptySocketDataValue = {
    ask: 0,
    bid: 0,
    close: 0,
    /** E.g. `'2020-10-19T05:44:58.143Z'` */
    datetime: '',
    high: 0,
    low: 0,
    open: 0,
    prevRef: 0,
    symbol: 'TSEA',
    qty: 0,
    volume: 0,
    openInterest: 0,
    timeValue: 0,
    limitUpPrice: 0,
    limitDownPrice: 0,
};
/** Option-compare, option-price共用 */
export const useOpbsProcessingState = createContainer(useOpbsProcessing);
