import { memo } from 'react';
import { css, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { meCheckHandlerAgentVEReadPublicAccountRead } from '~/modules/SDK/me/meCheckHandlerAgentWeb';
export const AvailableAccountSelect = memo(function AvailableAccountSelect({ privateAccounts, publicAccounts, changeDelegate, value }) {
    const legitUser = meCheckHandlerAgentVEReadPublicAccountRead.useCheck();
    return (<FormControl>
      <InputLabel disableAnimation={true}>選擇帳戶</InputLabel>
      <Select css={css `
          width: 200px;
        `} size='small' label='選擇帳戶' value={value ?? ''} onChange={e => changeDelegate(e)}>
        <MenuItem key={'none1'} disabled value={'none1'}>
          個人帳戶查詢 ---
        </MenuItem>
        {privateAccounts.map(account => (<MenuItem key={account.id} value={account.id}>
            {account.name}
          </MenuItem>))}
        <MenuItem key={'none2'} disabled value={'none2'}>
          公開帳戶查詢 ---
        </MenuItem>
        {legitUser &&
            publicAccounts.map(account => privateAccounts.find(a => a.id === account.id) ? null : (<MenuItem key={account.id} value={account.id}>
                {account.id + '-' + account.name}
              </MenuItem>))}
      </Select>
    </FormControl>);
});
