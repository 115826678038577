import { memo } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { isCallOption } from '~/utils/optionsTranslator';
import { useOpbsStrategyLogicState, } from '~/modules/virtual-exchange-center/containers/useOpbsStrategyLogic';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
import { generalStrategies } from '~/modules/virtual-exchange-center/utils/opbsStrategyConstants';
const StrategiesSelect = memo(function StrategiesSelect() {
    const { state, acts, props } = useOpbsStrategyLogicState.useContainer();
    const { state: uiState } = useVirtualExchangeCenterParametersState.useContainer();
    return (<FormControl>
      <InputLabel disableAnimation={true}>策略</InputLabel>
      <Select size='small' label='策略' value={state.strategy} onChange={e => acts.setStrategy(e.target.value)}>
        {props.ALL_STRATEGIES.map(strategy => {
            return (!isCallOption(uiState.currentSymbol) &&
                strategy.key === generalStrategies.CALL_SPREAD) ||
                (isCallOption(uiState.currentSymbol) &&
                    strategy.key === generalStrategies.PUT_SPREAD) ? null : (<MenuItem key={strategy.key} value={strategy.key}>
              {strategy.chinese}
            </MenuItem>);
        })}
      </Select>
    </FormControl>);
});
export default StrategiesSelect;
