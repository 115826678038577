import isEmpty from 'lodash/isEmpty';
import { isCallOption } from '~/utils/optionsTranslator';
/** 跨式 symbol 判別 */
export const getStraddleStrategySymbol = (symbol) => {
    const opbsAsciiMonthCode = symbol.charCodeAt(symbol.length - 2);
    const opbsYear = symbol[symbol.length - 1];
    const opbsMask = symbol.substring(0, 8);
    const isCall = isCallOption(symbol);
    /** TX217600A2:M2 */
    const monthPair = isCall
        ? symbol.substring(8, 10) + ':' + String.fromCharCode(opbsAsciiMonthCode + 12) + opbsYear
        : String.fromCharCode(opbsAsciiMonthCode - 12) + opbsYear + ':' + symbol.substring(8, 10);
    return `${opbsMask}${monthPair}`;
};
/** 逆轉、轉換 symbol 判別 */
export const getConversionStrategySymbol = (symbol) => {
    const opbsAsciiMonthCode = symbol.charCodeAt(symbol.length - 2);
    const opbsYear = symbol[symbol.length - 1];
    const opbsMask = symbol.substring(0, 8);
    const isCall = isCallOption(symbol);
    /** TX217500A2-M2*/
    const monthPair = isCall
        ? symbol.substring(8, 10) + '-' + String.fromCharCode(opbsAsciiMonthCode + 12) + opbsYear
        : String.fromCharCode(opbsAsciiMonthCode - 12) + opbsYear + '-' + symbol.substring(8, 10);
    return `${opbsMask}${monthPair}`;
};
/** 勒式 symbol 判別 */
export const getStrangleStrategySymbol = (symbol, strangleItemSymbol) => {
    if (!strangleItemSymbol)
        return '';
    const opbsMask = symbol.substring(0, 3);
    const isCall = isCallOption(symbol);
    /** TX217800A2:18850M2 */
    const monthPair = isCall
        ? symbol.substring(3, 10) + ':' + strangleItemSymbol.substring(3, 10)
        : strangleItemSymbol.substring(3, 10) + ':' + symbol.substring(3, 10);
    return `${opbsMask}${monthPair}`;
};
/** 價差策略 symbol 判別 */
export const getVerticalSpreadStrategySymbol = (pair) => {
    // 若任一個是空字串，回傳空白字串
    if (pair.some(item => isEmpty(item)))
        return '';
    const opbsPreMask = pair[0].substring(0, 3);
    const opbsPostMask = pair[0].substring(8, 10);
    const processedPair = pair
        .map(item => item.substring(3, 8))
        .sort((a, b) => (isCallOption(pair[0]) ? b.localeCompare(a) : a.localeCompare(b)))
        .join('/');
    /** TX218850/20000M2 */
    return opbsPreMask + processedPair + opbsPostMask;
};
/**
 *
 * 以下為分解商品
 *
 *
 *
 *
 *
 */
export const decomposeSymbol = symbol => {
    if (_isConversionStrategy(symbol))
        return _decomposeConversionSymbol(symbol);
    else if (_isStraddleStrategy(symbol))
        return _decomposeStraddleSymbol(symbol);
    else if (_isStrangleStrategy(symbol))
        return _decomposeStrangleSymbol(symbol);
    else if (_isVerticalSpreadStrategy(symbol))
        return _decomposeVSpreadSymbol(symbol);
    else
        return [symbol];
};
export const decomposeQty = (originSymbol, symbol, qty, isFront) => {
    if (_isConversionStrategy(originSymbol))
        return _getConversionQty(symbol, qty);
    else if (_isStraddleStrategy(originSymbol))
        return _getStraddleQty(symbol, qty);
    else if (_isStrangleStrategy(originSymbol))
        return _getStrangleQty(symbol, qty);
    else if (_isVerticalSpreadStrategy(originSymbol)) {
        return _getVSpreadQty(symbol, qty, isFront ?? false);
    }
    else
        return qty;
};
export const decomposePrice = (originSymbol, price, isFront) => {
    // TODO: 確認逆轉、轉換
    if (_isConversionStrategy(originSymbol))
        return isFront ? (price > 0 ? price : -price) : 0;
    else if (_isStraddleStrategy(originSymbol))
        return isFront ? 0 : price;
    else if (_isStrangleStrategy(originSymbol))
        return isFront ? 0 : price;
    else if (_isVerticalSpreadStrategy(originSymbol)) {
        return isFront ? 0 : price;
    }
    else
        return price;
};
/** 跨式分解
 *
 * @param symbol
 * @return two symbols
 *
 * @example
 * // return [TXO19100A2, TXO19100M2]
 * _decomposeStraddleSymbol('TX217600A2:M2')
 *
 */
export const _decomposeStraddleSymbol = symbol => {
    const firstSymbol = symbol.slice(0, 10);
    const secondSymbol = symbol.slice(0, 8) + symbol.slice(11, 13);
    return [firstSymbol, secondSymbol];
};
/** 跨式確認部位
 *
 * @param symbol
 * @param qty
 * @return
 *
 * @example
 * // return 2
 * _getStraddleQty('TXO19100A2', -2)
 * // return -2
 * _getStraddleQty('TXO19100M2', -2)
 *
 */
export const _getStraddleQty = (symbol, qty) => qty;
/** 勒式分解
 *
 * @param symbol
 * @return two symbols
 *
 * @example
 * // return [TX217800A2, TX218850M2]
 * _decomposeStrangleSymbol('TX217800A2:18850M2')
 *
 */
export const _decomposeStrangleSymbol = symbol => {
    const mask = symbol.slice(0, 3);
    const firstSymbol = mask + symbol.slice(3, 10);
    const secondSymbol = mask + symbol.slice(11);
    return [firstSymbol, secondSymbol];
};
/** 勒式確認部位
 *
 * @param symbol
 * @param qty
 * @return
 *
 * @example
 * // return 2
 * _getStrangleQty('TX217800A2', -2)
 * // return -2
 * _getStrangleQty('TX218850M2', -2)
 *
 */
export const _getStrangleQty = (symbol, qty) => {
    return qty;
};
/** 逆轉、轉換分解
 *
 * @param symbol
 * @return two symbols
 *
 * @example
 * // return [TXO19100A2, TXO19100M2]
 * _decomposeConversionSymbol('TXO19100A2-M2')
 *
 */
export const _decomposeConversionSymbol = symbol => {
    const firstSymbol = symbol.slice(0, 10);
    const secondSymbol = symbol.slice(0, 8) + symbol.slice(11, 13);
    return [firstSymbol, secondSymbol];
};
/** 逆轉、轉換確認部位
 *
 * @param symbol
 * @param qty
 * @return
 *
 * @example
 * // return 2
 * _getConversionQty('TXO19100A2', -2)
 * // return -2
 * _getConversionQty('TXO19100M2', -2)
 *
 * // REFACTOR
 */
export const _getConversionQty = (symbol, qty) => {
    // Reversal: 買call、賣put
    if (qty < 0 && isCallOption(symbol))
        return Math.abs(qty);
    // Conversion: 賣call、買put
    else if (qty > 0 && isCallOption(symbol))
        return -qty;
    else
        return qty;
};
/** 價差分解
 *
 * @param symbol
 * @return two symbols
 *
 * @example
 * // return [TX218850M2, TX220000M2]
 * _decomposeVSpreadSymbol('TX218850/20000M2')
 *
 */
export const _decomposeVSpreadSymbol = symbol => {
    const prevMask = symbol.slice(0, 3);
    const postMask = symbol.slice(14, 16);
    const firstSymbol = prevMask + symbol.slice(3, 8) + postMask;
    const secondSymbol = prevMask + symbol.slice(9, 14) + postMask;
    return [firstSymbol, secondSymbol];
};
/** 價差確認部位
 *
 * @param symbol
 * @param qty
 * @return
 *
 * @example
 * // return 2
 * _getVSpreadQty('TXO19100A2', -2)
 * // return -2
 * _getVSpreadQty('TXO19100M2', -2)
 *
 */
export const _getVSpreadQty = (symbol, qty, isFront) => {
    // 買權：履約價格高放前面，不論是空頭或多頭
    // 1. 買權多頭 qty > 0：賣履約高、買履約低
    // 2. 買權空頭 qty < 0：買履約高、賣履約低
    //if (isCallOption(symbol)) return isFront ? -qty : qty
    // 賣權：履約價格低放前面，不論是空頭或多頭
    // 1. 賣權多頭 qty < 0：買履約低、賣履約高
    // 2. 賣權空頭 qty > 0：賣履約低、買履約高
    //else return isFront ? -qty : qty
    return isFront ? -qty : qty;
};
export const _isConversionStrategy = (symbol) => {
    return symbol.includes('-');
};
export const _isVerticalSpreadStrategy = (symbol) => {
    return symbol.includes('/');
};
export const _isStraddleStrategy = (symbol) => {
    const items = symbol.split(':');
    return items && items.length === 2 && items[1].length === 2;
};
export const _isStrangleStrategy = (symbol) => {
    const items = symbol.split(':');
    return items && items.length === 2 && items[1].length > 2;
};
