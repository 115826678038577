/**
 * 取得合約「最後有資料」的日期
 * - 過去合約 --> 回傳到期日
 * - 未來合約 --> 回傳現在
 * @param contractCode
 * @returns YYYY-MM-DD
 * 假設2021-11-15:
 * - 202111W2 2021-11-10
 * - 202109W1 2021-09-01
 * - 202109W5 2021-09-29
 * - 202108 2021-08-18
 * - 202108W4 2021-08-25
 *
 * - 202111 2021-11-15
 * - 202112 2021-11-15
 */
export const getContractDueDate = (contractCode, base) => {
    if (!contractCode)
        return;
    let now = base;
    const nowWeekDay = now.weekday();
    /** 若為假日，則調整回禮拜五 */
    now = now.add(nowWeekDay === 0 ? -2 : nowWeekDay === 6 ? -1 : 0, 'day');
    const contractMonth = parseInt(contractCode.substring(4, 6));
    const isWeek = contractCode.includes('W');
    /** 該合約的月份中，第一個禮拜三 */
    const firstWednesday = now
        .month(contractMonth - 1)
        .startOf('month')
        .weekday(3);
    /** 月選回傳3，否則合約字串 `202101Wn`取得`n`  */
    const nOfweek = isWeek ? parseInt(contractCode[contractCode.length - 1]) : 3;
    /** 取得到期日 */
    const due = firstWednesday.add(nOfweek - 1, 'week');
    /** 若到期時間為未來，則回傳今日 */
    return due.isBefore(now) ? due.format('YYYY-MM-DD') : now.format('YYYY-MM-DD');
};
