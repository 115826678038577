import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { DataGrid } from '@mui/x-data-grid';
import { expectType } from '~/utils/tsd';
import dayAPI from '~/utils/dayAPI';
import { globalGreen, globalRed } from '~/modules/AppLayout/Colors';
import { fontWeight600 } from '~/css/font';
import { localeDataGrid } from '~/configs/localeDataGrid';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
import { SymbolReadable } from '~/modules/SDK/Symbol/SymbolReadable';
const StatementTable = memo(function StatementTable() {
    const statements = useTradeStore(s => s.statements);
    const data = useMemo(() => statements.map(statement => ({
        ...statement,
        id: statement.datetime + statement.symbol + statement.pnL,
    })), [statements]);
    return (<DataGrid css={css `
        margin: 0 24px;
        background: white;
      `} rowHeight={32} rows={data} hideFooter={false} density='comfortable' localeText={localeDataGrid} columns={columns}/>);
});
const priceRenderer = (params) => {
    const value = parseFloat(params.value);
    const hasDecimal = value.toString().indexOf('.') !== -1;
    return <p>{params.value.toFixed(hasDecimal ? 2 : 0)}</p>;
};
const columns = [
    {
        field: expectType('symbol'),
        headerName: '商品',
        flex: 3,
        renderCell: (params) => (<p>
        {params.value}&nbsp;
        <SymbolReadable symbol={params.value}/>
        {/* <SymbolName symbol={params.value}></SymbolName> */}
      </p>),
    },
    {
        field: expectType('datetime'),
        headerName: '平倉時間',
        flex: 1.5,
        renderCell: (params) => (<p>{dayAPI.utc(params.value).tz('Asia/Taipei').format('YYYY/MM/DD HH:mm')}</p>),
    },
    {
        field: expectType('openPositionAveragePrice'),
        headerName: '開倉均價',
        flex: 1,
        renderCell: priceRenderer,
    },
    { field: expectType('openPositionQty'), headerName: '開倉數量', flex: 1 },
    {
        field: expectType('closePositionAveragePrice'),
        headerName: '平倉均價',
        flex: 1,
        renderCell: priceRenderer,
    },
    { field: expectType('closePositionQty'), headerName: '平倉數量', flex: 1 },
    {
        field: expectType('pnL'),
        headerName: '損益',
        flex: 1,
        renderCell: (params) => {
            const value = parseInt(params.value);
            const color = value > 0 ? globalRed.r700 : globalGreen.g800;
            return (<p css={css `
            color: ${color};
            ${fontWeight600};
          `}>
          {value}
        </p>);
        },
    },
    { field: expectType('commission'), headerName: '手續費', flex: 1 },
    { field: expectType('tax'), headerName: '交易稅', flex: 1 },
];
export default StatementTable;
