import { css } from '@emotion/react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { memo } from 'react';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
export const OpbsModeToggle = memo(function OpbsModeToggle() {
    const { state, acts } = useVirtualExchangeCenterParametersState.useContainer();
    return (<ToggleButtonGroup fullWidth size='small' css={css `
        margin-bottom: 16px;
      `}>
      <ToggleButton value={false} onClick={() => {
            acts.setIsCompoundOption(false);
        }} selected={!state.isCompoundOption}>
        單式
      </ToggleButton>
      <ToggleButton value={true} onClick={() => {
            acts.setIsCompoundOption(true);
        }} selected={state.isCompoundOption}>
        複式
      </ToggleButton>
    </ToggleButtonGroup>);
});
