import useSWR from 'swr-0-5-6';
import urlcat from 'urlcat';
import { apirc } from '~/configs/apirc';
const taifexOptionsOiURL = apirc.unclassified.taifexOptionsOiURL.baseUrl;
/**
 * @param date 2021-11-11
 * @returns 目前可用合約、所有履約價格的未平倉量
 */
const useTaifexOptionsOiResource = (date) => {
    const swr = useSWR(urlcat(taifexOptionsOiURL, { date }));
    const data = swr.data;
    return data;
};
export default useTaifexOptionsOiResource;
