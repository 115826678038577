import { css } from '@emotion/react';
import { Tabs, Tab } from '@mui/material';
import React, { memo } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
import useMedia from '~/hooks/useMedia';
import dayAPI from '~/utils/dayAPI';
import { isOpbsSymbol, optionTranslator } from '~/utils/optionsTranslator';
import { OrderTileTemplate, OrderFlatTemplate, OrderTileItem, OrderFlatItem, PositionTileTemplate, PositionFlatTemplate, PositionFlatItem, PositionTileItem, TransactionTileItem, TransactionFlatItem, TransactionFlatTemplate, TransactionTileTemplate, } from '~/modules/virtual-exchange-center/components/RecordTable/ItemTemplate';
import { useVirtualExchangeCenterParametersState, } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
import { AscOrDescSelect, ColumnSelect, } from '~/modules/virtual-exchange-center/components/RecordTable/OrderSetter';
const outerPanelCss = css `
  padding: 0;
  height: 100%s;
  overflow-y: auto;
`;
const RecordTable = memo(function RecordTable() {
    const transactions = useTradeStore(state => state.transactions);
    const positions = useTradeStore(state => state.positions);
    const orders = useTradeStore(state => state.orders);
    const pendingOrders = useTradeStore(state => state.pendingOrders);
    const { state: uiState, acts: uiActs } = useVirtualExchangeCenterParametersState.useContainer();
    const { isPc } = useMedia();
    const gridOrFlatCss = css `
    ${uiState.templateType === 'grid' ? flex.wrap.default : flex.v.default};
    align-content: flex-start;
    margin: 16px 0;
    & > *:first-of-type {
      opacity: 0.4;
    }
    & > * {
      margin: ${uiState.templateType === 'grid' ? 8 : 0}px;
      min-width: ${uiState.templateType === 'grid' ? '120px' : '50%'};
    }
    height: ${isPc ? '300px' : 'inherit'};
    overflow-y: auto;
  `;
    const currentColumn = uiState.tabValue === '2' || uiState.tabValue === '3'
        ? uiState.column
        : uiState.tabValue === '1'
            ? uiState.column
            : uiState.column;
    const processedPositions = positions.sort((a, b) => positionSortFunc(uiState.orderingDirection, currentColumn, a, b));
    const processedPendingOrder = pendingOrders.sort((a, b) => orderSortFunc(uiState.orderingDirection, currentColumn, a, b));
    const processedOrder = orders.sort((a, b) => orderSortFunc(uiState.orderingDirection, currentColumn, a, b));
    const processedTransactions = transactions.sort((a, b) => transactionSortFunc(uiState.orderingDirection, currentColumn, a, b));
    const offsetEventHandler = (event, symbol, qty, price, isOpbs) => {
        /** 僅在這裡可以設置 isOffsetMode 為 true */
        uiActs.handleOpenForm(event, symbol, isOpbs, true);
        useTradeStore.setState({ qty: Math.abs(qty) });
        useTradeStore.setState({ price: 'M' });
        uiActs.setPriceType('M');
        uiActs.setBs(qty > 0 ? 'S' : 'B');
        /** 預設限價，因此在切換時要注意會變成 undefined，解法在這裡先記住 */
        uiActs.setTempOffsetPrice(price);
    };
    return (<>
      <Tabs value={uiState.tabValue} onChange={(e, newVal) => uiActs.setTabValue(newVal.toString())}>
        <Tab value={'4'} label='未平倉部位'/>
        <Tab value={'1'} label='成交明細'/>
        <Tab value={'2'} label='委託明細'/>
        <Tab value={'3'} label='有效委託'/>
      </Tabs>

      <div css={flex.h.crossCenter}>
        <ColumnSelect />
        <AscOrDescSelect />
      </div>
      {uiState.tabValue === '4' && (<div css={outerPanelCss}>
          <div css={gridOrFlatCss}>
            {uiState.templateType === 'grid' ? <PositionTileTemplate /> : <PositionFlatTemplate />}
            {processedPositions.map((position, index) => uiState.templateType === 'grid' ? (<PositionTileItem key={index} position={position} offsetHandler={offsetEventHandler}/>) : (<PositionFlatItem key={index} position={position} offsetHandler={offsetEventHandler}/>))}
          </div>
        </div>)}
      {uiState.tabValue === '1' && (<div css={outerPanelCss}>
          <div css={gridOrFlatCss}>
            {uiState.templateType === 'grid' ? (<TransactionTileTemplate />) : (<TransactionFlatTemplate />)}
            {processedTransactions.map((transaction, index) => uiState.templateType === 'grid' ? (<TransactionTileItem key={index} transaction={transaction}/>) : (<TransactionFlatItem key={index} transaction={transaction}/>))}
          </div>
        </div>)}
      {uiState.tabValue === '2' && (<div css={outerPanelCss}>
          <div css={gridOrFlatCss}>
            {uiState.templateType === 'grid' ? <OrderTileTemplate /> : <OrderFlatTemplate />}
            {processedOrder.map(order => uiState.templateType === 'grid' ? (<OrderTileItem key={order.id} order={order}/>) : (<OrderFlatItem key={order.id} order={order}/>))}
          </div>
        </div>)}

      {uiState.tabValue === '3' && (<div css={outerPanelCss}>
          <div css={gridOrFlatCss}>
            {uiState.templateType === 'grid' ? <OrderTileTemplate /> : <OrderFlatTemplate />}
            {processedPendingOrder.map(order => uiState.templateType === 'grid' ? (<OrderTileItem key={order.id} order={order}/>) : (<OrderFlatItem key={order.id} order={order}/>))}
          </div>
        </div>)}
    </>);
});
export default RecordTable;
const orderSortFunc = (direction, currentColumn, a, b) => {
    if (currentColumn === 'symbol') {
        const aSymbol = isOpbsSymbol(a[currentColumn])
            ? optionTranslator(a[currentColumn])
            : a[currentColumn];
        const bSymbol = isOpbsSymbol(b[currentColumn])
            ? optionTranslator(b[currentColumn])
            : b[currentColumn];
        return direction === 'desc' ? bSymbol.localeCompare(aSymbol) : aSymbol.localeCompare(bSymbol);
    }
    if (currentColumn === 'createdDatetime') {
        const [aUnix, bUnix] = [dayAPI(a[currentColumn]).unix(), dayAPI(b[currentColumn]).unix()];
        return direction === 'desc' ? bUnix - aUnix : aUnix - bUnix;
    }
    return direction === 'desc'
        ? b[currentColumn] - a[currentColumn]
        : a[currentColumn] - b[currentColumn];
};
const transactionSortFunc = (direction, currentColumn, a, b) => {
    if (currentColumn === 'symbol') {
        const aSymbol = isOpbsSymbol(a[currentColumn])
            ? optionTranslator(a[currentColumn], true)
            : a[currentColumn];
        const bSymbol = isOpbsSymbol(b[currentColumn])
            ? optionTranslator(b[currentColumn], true)
            : b[currentColumn];
        return direction === 'desc' ? bSymbol.localeCompare(aSymbol) : aSymbol.localeCompare(bSymbol);
    }
    if (currentColumn === 'datetime') {
        const [aUnix, bUnix] = [dayAPI(a[currentColumn]).unix(), dayAPI(b[currentColumn]).unix()];
        return direction === 'desc' ? bUnix - aUnix : aUnix - bUnix;
    }
    return direction === 'desc'
        ? b[currentColumn] - a[currentColumn]
        : a[currentColumn] - b[currentColumn];
};
const positionSortFunc = (direction, currentColumn, a, b) => {
    if (currentColumn === 'symbol') {
        const aSymbol = isOpbsSymbol(a[currentColumn])
            ? optionTranslator(a[currentColumn], true)
            : a[currentColumn];
        const bSymbol = isOpbsSymbol(b[currentColumn])
            ? optionTranslator(b[currentColumn], true)
            : b[currentColumn];
        return direction === 'desc' ? bSymbol.localeCompare(aSymbol) : aSymbol.localeCompare(bSymbol);
    }
    return direction === 'desc'
        ? b[currentColumn] - a[currentColumn]
        : a[currentColumn] - b[currentColumn];
};
