import urlcat from 'urlcat';
import { apirc } from '~/configs/apirc';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
import axios from 'axios-0-27-2';
import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
const prefixUrl = apirc.trade.strategyCenterURL.baseUrl;
const _axios = axios.create({
    baseURL: prefixUrl,
});
export var StrategyCenter;
(function (StrategyCenter) {
    // USER
    StrategyCenter["user"] = "/api/user";
    StrategyCenter["signin"] = "/api/user/signin";
    StrategyCenter["register"] = "/api/user/register";
    StrategyCenter["getUserStrategies"] = "/api/user/strategies";
    // STRATEGY
    StrategyCenter["getAvailableStrategies"] = "/api/user/strategies";
    StrategyCenter["specStrategy"] = "/api/strategies/:name";
    StrategyCenter["addStrategy"] = "/api/strategies";
    StrategyCenter["getStrategyPositions"] = "/api/strategies/:name/positions";
    StrategyCenter["cleanupStrategy"] = "/api/strategies/:name/records";
    StrategyCenter["getStrategyDailyProfit"] = "/api/strategies/:name/dailyProfit";
    // ORDER
    StrategyCenter["orders"] = "/api/strategies/:name/orders";
    StrategyCenter["getPendingOrders"] = "/api/strategies/:name/orders/pending";
    StrategyCenter["getPreOrders"] = "/api/strategies/:name/orders/pre";
    StrategyCenter["createSimulatedOrder"] = "/api/strategies/:name/orders/simulated";
    StrategyCenter["createMultiSimulatedOrder"] = "/api/strategies/:name/orders/simulatedList";
    StrategyCenter["ordersById"] = "/api/strategies/:name/orders/:orderId";
    // TRANSACTION
    StrategyCenter["transactions"] = "/api/strategies/:name/transactions";
    StrategyCenter["transactionsStatements"] = "/api/strategies/:name/transactions/statements";
})(StrategyCenter || (StrategyCenter = {}));
/**
 * @private
 * @see https://strategy-center.e01.futures-op.com/swagger/index.html
 */
export const AuthTradeAPI = new (class AuthTradeAPI extends Urlu {
    /** USER */
    async signin(payload) {
        return _axios
            .post(StrategyCenter.signin, payload, {
            headers: {
                accept: '*/*',
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
            useTradeStore.setState({
                strategyAuth: res.data,
            });
            localStorage.setItem('futuresai.futuresai.strategy-center-auth', res.data);
        })
            .then(() => {
            this.getAvailableStrategies();
        });
    }
    async register(payload) {
        return _axios
            .post(StrategyCenter.register, payload, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then(() => {
            // TODO: directly login?
            this.signin({ account: payload.account, password: payload.password });
        });
    }
    async changeMyName(name) {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        const body = {
            name,
        };
        return _axios
            .put(StrategyCenter.user, {
            data: body,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(() => {
            this.getMe();
        });
    }
    async getMe() {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        return _axios
            .get(StrategyCenter.getAvailableStrategies, {
            headers: {
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(res => {
            useTradeStore.setState({ strategyAuthUserName: res.data.name });
        });
    }
    async getAvailableStrategies() {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        return _axios
            .get(StrategyCenter.getAvailableStrategies, {
            headers: {
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(res => {
            useTradeStore.setState({ strategies: res.data });
            useTradeStore.setState({ currentStrategyName: res.data[0].name });
            this.getTransactions();
            this.getOrders();
            this.getPendingOrders();
            this.getPositions();
        });
    }
    /** STRATEGY */
    async getPositions() {
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        const url = urlcat(StrategyCenter.getStrategyPositions, { name: currentStrategyName });
        const strategyAuth = useTradeStore.getState().strategyAuth;
        return _axios
            .get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(res => {
            useTradeStore.setState({
                positions: res.data,
            });
        })
            .catch(() => {
            // 就是隨便找一個到了頁面就會 call 的資源，如果 token 無效，清除 local 的
            localStorage.removeItem('futuresai.futuresai.strategy-center-auth');
            useTradeStore.setState({ strategyAuth: undefined });
        });
    }
    async getDailyProfit(beginDatetime, endDatetime) {
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        const url = urlcat(StrategyCenter.getStrategyDailyProfit, {
            name: currentStrategyName,
            beginDatetime,
            endDatetime,
        });
        const strategyAuth = useTradeStore.getState().strategyAuth;
        return _axios
            .get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(res => {
            useTradeStore.setState({
                dailyProfit: res.data,
            });
        });
    }
    async cleanupStrategyRecords() {
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        const url = urlcat(StrategyCenter.cleanupStrategy, { name: currentStrategyName });
        const strategyAuth = useTradeStore.getState().strategyAuth;
        return _axios
            .delete(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(() => {
            this.getTransactions();
            this.getStatements(); // TODO:
            this.getOrders();
            this.getPendingOrders();
        });
    }
    /** ORDER */
    async createOrder(order) {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        return _axios.post(urlcat(StrategyCenter.orders, { name: currentStrategyName }), order, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        });
    }
    async getOrders() {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        return _axios
            .get(urlcat(StrategyCenter.orders, { name: currentStrategyName }), {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(res => {
            useTradeStore.setState({
                orders: res.data,
            });
        });
    }
    async getPendingOrders() {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        return _axios
            .get(urlcat(StrategyCenter.getPendingOrders, { name: currentStrategyName }), {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(res => {
            useTradeStore.setState({
                pendingOrders: res.data,
            });
        });
    }
    async deleteOrder(orderId) {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        const url = urlcat(StrategyCenter.ordersById, {
            name: currentStrategyName,
            orderId,
        });
        return _axios
            .delete(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(() => {
            this.getOrders();
            this.getPendingOrders();
        });
    }
    /** TRANSACTION */
    async getTransactions() {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        const url = urlcat(StrategyCenter.transactions, {
            name: currentStrategyName,
        });
        return _axios
            .get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(res => {
            useTradeStore.setState({
                transactions: res.data,
            });
        });
    }
    async getStatements(option) {
        const strategyAuth = useTradeStore.getState().strategyAuth;
        const currentStrategyName = useTradeStore.getState().currentStrategyName;
        const url = urlcat(StrategyCenter.transactionsStatements, {
            name: currentStrategyName,
            ...option,
        });
        return _axios
            .get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${strategyAuth}`,
            },
        })
            .then(res => {
            useTradeStore.setState({
                statements: res.data,
            });
        });
    }
})(apirc.trade.strategyCenterURL.baseUrl);
