import React, { memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { IconButton, Paper } from '@mui/material';
import { ai, flex } from '~/modules/AppLayout/FlexGridCss';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { useInterval, useLifecycles } from 'react-use';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { useQuoteUpdateAnimationCSS } from '~/modules/SDK/socket/useQuoteUpdateAnimationCSS';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { SymbolSearch } from '~/modules/SDK/Symbol/SymbolSearch';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { BsTrash } from 'react-icons/bs';
import { globalRed } from '~/modules/AppLayout/Colors';
import { fontWeight600 } from '~/css/font';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { getDeltaColor } from '~/modules/virtual-exchange-center/utils/getDeltaColors';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
export const SelfWrapper = memo(function SelfWrapper() {
    const { state: userStockListState, acts: userStockListActs } = useSymbolWatchList('virtual-exchange-group');
    const symbolAdd = (newValue) => {
        if (userStockListState.symbolListArray?.find(s => s === newValue.symbol))
            return;
        userStockListActs.addSymbol(newValue.symbol, 100);
    };
    useInterval(() => {
        if (!userStockListState.symbolListArray) {
            userStockListActs.getSymbolList();
        }
    }, 5000);
    return (<div css={css `
        height: 40vh;
        & > *:nth-of-type(1) {
          width: 200px;
        }
      `}>
      <SymbolSearch onChangeCb={symbolAdd}/>
      <div css={css `
          height: calc(100% - 48px);
          ${flex.wrap.default};
          ${ai.flexStart};
          & > * {
            width: 120px;
            margin: 4px;
          }
        `}>
        {userStockListState.symbolListArray?.map((a, i) => (<Item_ key={a} symbol={a} deleteCb={userStockListActs.removeSymbol}/>))}
      </div>
    </div>);
});
export const Item_ = memo(function Item_({ symbol, deleteCb }) {
    const { acts } = useVirtualExchangeCenterParametersState.useContainer();
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    useLifecycles(() => {
        useSignalrStore.getState().subscribeAdd([symbol], 'ohlc');
    }, () => {
        useSignalrStore.getState().subscribeRemove([symbol], 'ohlc');
    });
    const symbolInfo = useSignalrStoreValueOHLC(s => s.value[symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const quoteAnimation = useQuoteUpdateAnimationCSS(symbolInfo?.close);
    const handleSelectSymbol = useCallback((e) => {
        acts.handleOpenForm(e, symbol);
    }, [acts, symbol]);
    return (<Paper elevation={2} css={css `
        cursor: pointer;
        ${quoteAnimation}66;
        color: ${getDeltaColor(quoteChanges?.closeChangePercent)};
      `}>
      <div css={css `
          padding: 4px 8px;
          position: relative;
          * {
            margin: 0;
          }
          & > *:nth-of-type(1) {
            font-size: 0.8rem;
            ${fontWeight600};
          }
          & > *:nth-of-type(2) {
            font-size: 1rem;
            ${fontWeight600};
          }
          & > *:nth-of-type(3) {
            font-size: 0.9rem;
          }
        `}>
        <div css={flex.h.crossCenter}>
          <p>{symbol}</p>
          <p> {dictionary[symbol]}</p>
        </div>
        <div>
          <p>{symbolInfo?.close}</p>
        </div>
        <div css={flex.h.default} onClick={handleSelectSymbol}>
          {symbolInfo ? (<>
              <p>{quoteChanges.deltaChangeString}</p>
              <p> &nbsp;({quoteChanges.closeChangePercentString})</p>
            </>) : ('-')}
        </div>
        <IconButton css={css `
            position: absolute;
            bottom: 4px;
            right: 4px;
            color: ${globalRed.r800};
            padding: 0;
          `} onClick={() => {
            deleteCb(symbol);
        }}>
          <BsTrash />
        </IconButton>
      </div>
    </Paper>);
});
