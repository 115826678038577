import { css } from '@emotion/react';
import { Divider, Button, Dialog, TextField, } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { memo, useEffect, useState } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
import { tradeAPI } from '~/modules/SDK/Trade/tradeAPI';
import { useText } from '~/hooks/useText';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { MdAdd } from 'react-icons/md';
import { AvailableAccountSelect } from '~/modules/virtual-exchange-center/components/AccountRelated/AvailableAccountSelect';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
import { ChipItem } from '~/modules/virtual-exchange-center/components/ChipItem';
import ChartDialogBtns from '~/modules/virtual-exchange-center/components/ChartDialogBtns';
/**
 * - 有主站會員登入，有的話
 *
 * 1. 確定有無初始化過`1 to 1的 trade user`，正常顯示隸屬該 user 底下的 account
 * 2. 沒有的話，則給個初始化帳號按鈕
 *
 * - 否則先登入主站
 */
export const UserAccountModule = memo(function UserAccountModule() {
    const hasTradeUser = useTradeStore(state => state.hasTradeUser);
    const me = useMeStore(s => s.meUserState);
    return (<div css={flex.h.crossCenter}>
      {me ? (!hasTradeUser ? (<Button variant='contained' color='primary' onClick={() => {
                tradeAPI.createUser('defaultUser');
            }}>
            初始化使用者
          </Button>) : (<>
            <CreateAccount />
            <Divider css={verticalDividerCss} orientation='vertical' flexItem/>
            <AccountSelect />
            <ChartDialogBtns />
            <Divider css={verticalDividerCss} orientation='vertical' flexItem/>
            <CurrentAccountDetails />
            <Divider css={verticalDividerCss} orientation='vertical' flexItem/>
          </>)) : null}
    </div>);
});
const AccountSelect = memo(function AccountSelect() {
    const accounts = useTradeStore(state => state.accounts);
    const publicAccounts = useTradeStore(state => state.publicAccounts);
    const currentSelectAccountId = useTradeStore(state => state.currentSelectAccountId);
    const { state, acts } = useVirtualExchangeCenterParametersState.useContainer();
    /** 預設選第一個 */
    useEffect(() => {
        if (!currentSelectAccountId && accounts.length)
            useTradeStore.setState({
                currentSelectAccountId: accounts[0].id,
                currentSelectAccountBalance: accounts[0].balance,
            });
    }, [accounts, currentSelectAccountId]);
    const handleAccountChange = (event) => {
        const item = accounts.concat(publicAccounts).find(a => a.id === event.target.value);
        // 還原委託單狀態
        acts.handleOpenForm(undefined, '', false, false);
        acts.setStep(0);
        if (state.module === 'self')
            acts.setModule('stock');
        // 還原委託單狀態
        useTradeStore.setState({
            currentSelectAccountId: item?.id,
        });
    };
    return (<>
      <AvailableAccountSelect changeDelegate={handleAccountChange} value={currentSelectAccountId} privateAccounts={accounts} publicAccounts={publicAccounts}/>
    </>);
});
const CurrentAccountDetails = memo(function CurrentAccountDetails() {
    const currentSelectAccountBalance = useTradeStore(state => state.currentSelectAccountBalance);
    const currentSelectAccountEquity = useTradeStore(state => state.currentSelectAccountEquity);
    const currentSelectAccountUnrealizedPnL = useTradeStore(state => state.currentSelectAccountUnrealizedPnL);
    return (<>
      <ChipItem label={'權益總值'} value={currentSelectAccountBalance}/>

      <ChipItem label={'已實現損益'} value={currentSelectAccountEquity}/>

      <ChipItem label={'未實現損益'} value={currentSelectAccountUnrealizedPnL}/>
    </>);
});
const CreateAccount = memo(function CreateAccount() {
    const [open, setOpen] = useState(false);
    const name = useText();
    return (<>
      <Button color='warning' variant='contained' onClick={() => {
            setOpen(true);
        }}>
        <MdAdd /> 建立帳戶
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div css={css `
            ${flex.v.default};
            padding: 32px 16px;
          `}>
          <TextField type='text' label='帳戶名稱' value={name.value} onChange={name.acts.onChange}/>
          <Button variant='contained' color='info' disabled={isEmpty(name.value)} onClick={() => {
            if (name.value)
                tradeAPI.createAccount(name.value);
            setOpen(false);
        }}>
            建立
          </Button>
        </div>
      </Dialog>
    </>);
});
const verticalDividerCss = css `
  margin: 0 16px;
`;
