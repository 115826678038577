import { css } from '@emotion/react';
import { Paper, IconButton, Stepper, StepLabel, StepContent, Step, Button, Popper, } from '@mui/material';
import React, { memo, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useAsyncFn } from 'react-use';
import { SymbolReadable } from '~/modules/SDK/Symbol/SymbolReadable';
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore';
import { tradeAPI } from '~/modules/SDK/Trade/tradeAPI';
import isEmpty from 'lodash/isEmpty';
import Notification from '~/modules/notification/Notification';
import { notificationProxy } from '~/modules/notification/notificationProxy';
import { SnackbarMessage } from '~/components/SnackbarWarning';
import { fontWeight600 } from '~/css/font';
import { isOpbsSymbol, opbsAdvancedTranslator } from '~/utils/optionsTranslator';
import { tellTheMessageLevel } from '~/modules/virtual-exchange-center/utils/tellMessageLevel';
import { useOpbsStrategyLogicState } from '~/modules/virtual-exchange-center/containers/useOpbsStrategyLogic';
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters';
import { OpbsModeToggle } from '~/modules/virtual-exchange-center/components/OrderForm/OpbsModeToggle';
import { Form } from '~/modules/virtual-exchange-center/components/OrderForm/ModeOpbs';
import { getStraddleStrategySymbol, getConversionStrategySymbol, getStrangleStrategySymbol, getVerticalSpreadStrategySymbol, } from '~/modules/virtual-exchange-center/utils/opbsOrderUtils';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { AuthTradeAPI } from '~/modules/SDK/Trade/AuthTradeAPI';
const OrderForm = memo(function OrderForm() {
    const { state, acts } = useVirtualExchangeCenterParametersState.useContainer();
    const symbol = state.currentSymbol;
    const price = useTradeStore(s => s.price);
    const qty = useTradeStore(s => s.qty);
    const privateAccounts = useTradeStore(s => s.accounts);
    const { state: opbsState } = useOpbsStrategyLogicState.useContainer();
    const currentSelectAccountId = useTradeStore(s => s.currentSelectAccountId);
    const isCurrentAccountIdPrivate = !!privateAccounts.find(a => a.id === currentSelectAccountId);
    const handleCloseForm = () => acts.setFormAnchorEl(null);
    const isStrategyCenter = fr_agents.is['futuresai@strategy_center'];
    const [, apiCall] = useAsyncFn(async (theSymbol, theBs, thePrice, theOrderType, theQ) => {
        return (isStrategyCenter ? AuthTradeAPI : tradeAPI)
            .createOrder({
            symbol: theSymbol,
            bs: theBs,
            qty: theQ || 1,
            positionType: 'A',
            orderType: theOrderType,
            price: thePrice,
        })
            .then(res => {
            const level = tellTheMessageLevel(res.data);
            handleNotify(res.data, level, 2000);
            if (isStrategyCenter) {
                AuthTradeAPI.getOrders();
                AuthTradeAPI.getPendingOrders();
                AuthTradeAPI.getPositions();
                AuthTradeAPI.getTransactions();
            }
            else {
                tradeAPI.getOrders(isCurrentAccountIdPrivate ? false : true);
                tradeAPI.getPendingOrders(isCurrentAccountIdPrivate ? false : true);
                tradeAPI.getPositions(isCurrentAccountIdPrivate ? false : true);
                tradeAPI.getTransactions(isCurrentAccountIdPrivate ? false : true);
            }
        });
    }, []);
    const isOpbsMulti = state.isOpbs && state.isCompoundOption && !state.isOffsetMode;
    /** 複式單選項沒選好警告 start */
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorReason, setErrorReason] = useState('');
    const handleClose = (event, reason) => {
        setErrorOpen(false);
    };
    /** 複式單選項沒選好警告 end */
    const handleNextStepSituation = () => {
        if (state.isCompoundOption) {
            if ((opbsState.isStraddleStrategy && isEmpty(opbsState.straddleItem)) ||
                (opbsState.isReversalConversionStrategy && isEmpty(opbsState.reversalConversionItem)) ||
                (opbsState.isStrangleStrategy && isEmpty(opbsState.strangleItem)) ||
                (opbsState.isVerticalSpreadStrategy && isEmpty(opbsState.pair))) {
                setErrorOpen(true);
                setErrorReason('請點選對應組合商品');
            }
            else
                acts.setStep(1);
        }
        else
            acts.setStep(1);
    };
    const handleGoBack = () => {
        acts.setStep(0);
    };
    /** 委託單回傳對應的訊息 UI 通知 start */
    const notifyQueueProxySetter = notificationProxy;
    const handleNotify = (ms, level, long) => {
        notifyQueueProxySetter.isOpen = true;
        notifyQueueProxySetter.message = ms;
        notifyQueueProxySetter.msLevel = level ?? 'info';
        notifyQueueProxySetter.duration = long ?? 5000;
    };
    /** 委託單回傳對應的訊息 UI 通知 end */
    let orderSymbol = state.currentSymbol;
    if (isOpbsMulti) {
        if (opbsState.strategy === 'STRADDLE')
            orderSymbol = getStraddleStrategySymbol(symbol);
        else if (opbsState.strategy === 'REVERSAL_CONVERSION')
            orderSymbol = getConversionStrategySymbol(symbol);
        else if (opbsState.strategy === 'STRANGLE')
            orderSymbol = getStrangleStrategySymbol(symbol, opbsState.strangleItem);
        else if (opbsState.isVerticalSpreadStrategy)
            orderSymbol = getVerticalSpreadStrategySymbol([state.currentSymbol, opbsState.pair]);
    }
    return (<Popper id='popper' css={css `
        z-index: 4;
      `} open={state.open} anchorEl={state.formAnchorEl}>
      <Paper css={css `
          position: relative;
        `}>
        <div css={css `
            ${flex.h.crossCenter};
            & > h2 {
              margin: 8px;
              margin-left: 16px;
              margin-right: 16px;
            }
          `}>
          {state.isOpbs ? (<h2>
              <SymbolReadable symbol={symbol}/>
            </h2>) : (<h2>
              {symbol}&nbsp; <SymbolReadable symbol={symbol}/>
            </h2>)}
        </div>
        <Stepper css={css `
            margin: 0 16px;
            padding-bottom: 16px;
          `} activeStep={state.step} orientation='vertical'>
          <Step key={1}>
            <StepLabel>委託條件</StepLabel>
            <StepContent>
              <div css={flex.v.default}>
                {state.isOpbs && !state.isOffsetMode && <OpbsModeToggle />}

                <Form tradeCb={handleCloseForm} isOpbsMulti={isOpbsMulti}/>
                <SnackbarMessage open={errorOpen} closeDelegate={handleClose} message={errorReason}/>
                <Button onClick={handleNextStepSituation} fullWidth variant='contained'>
                  下一步
                </Button>
              </div>
            </StepContent>
          </Step>
          <Step key={2}>
            <StepLabel>確認</StepLabel>
            <StepContent>
              <div>
                <div css={areYouSureCss}>
                  <div css={flex.h.crossCenter}>
                    <p css={areYouSureKeyCss}>商品：</p>
                    <p css={areYouSureValueCss}>
                      {isOpbsSymbol(orderSymbol)
            ? opbsAdvancedTranslator(orderSymbol)
            : orderSymbol}
                    </p>
                  </div>
                  <div css={flex.h.crossCenter}>
                    <p css={areYouSureKeyCss}>價格：</p>
                    <p css={areYouSureValueCss}>{price === 'M' ? '市價' : price}</p>
                  </div>
                  <div css={flex.h.crossCenter}>
                    <p css={areYouSureKeyCss}>買賣別：</p>
                    <p css={areYouSureValueCss}>
                      {state.bs.replace('B', '買進').replace('S', '賣出')}
                    </p>
                  </div>
                  <div css={flex.h.crossCenter}>
                    <p css={areYouSureKeyCss}>口數：</p>
                    <p css={areYouSureValueCss}>{qty}</p>
                  </div>
                  <div css={flex.h.crossCenter}>
                    <p css={areYouSureKeyCss}>掛單類型：</p>
                    <p css={areYouSureValueCss}>{state.orderType}</p>
                  </div>
                </div>
                <div css={actionsCss}>
                  <Button onClick={handleGoBack} fullWidth variant='outlined'>
                    返回
                  </Button>
                  <Button onClick={() => {
            handleCloseForm();
            acts.setStep(0);
            apiCall(orderSymbol, state.bs, price ?? 'M', state.orderType, qty ?? 1);
        }} fullWidth variant='contained'>
                    送出
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
          <_Close />
          <Notification anchorHorizontal='right' anchorVertical='top'/>
        </Stepper>
      </Paper>
    </Popper>);
});
export default OrderForm;
const _Close = memo(function _Close() {
    const { acts } = useVirtualExchangeCenterParametersState.useContainer();
    return (<IconButton size='large' onClick={() => {
            acts.handleOpenForm(undefined, '', false, false);
            acts.setStep(0);
        }} css={css `
        position: absolute;
        top: -16px;
        right: -16px;
      `} color='error'>
      <MdCancel />
    </IconButton>);
});
const EVERY_STEP_WIDTH = 360;
const areYouSureCss = css `
  ${flex.v.default};
  gap: 4px;
  width: ${EVERY_STEP_WIDTH}px;
  & p {
    margin: 0;
  }
`;
const areYouSureKeyCss = css `
  font-size: 12px;
`;
const areYouSureValueCss = css `
  ${fontWeight600};
  font-size: 18px;
`;
const actionsCss = css `
  ${flex.h.crossCenter};
  gap: 8px;
  margin-top: 8px;
`;
