import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import { useOptionContractCodeState } from '~/modules/options/shareContainers/useOptionContractCodeState';
import { getContractDueDate } from '~/modules/options/utils/getContractDueDateUtil';
import useTaifexOptionsOiResource from '~/modules/options/api/useTaifexOptionsOiResource';
import dayAPI from '~/utils/dayAPI';
const useOpenInterest = () => {
    const { state: { month: contractCode }, } = useOptionContractCodeState.useContainer();
    const preContractCode = usePrevious(contractCode);
    const [date, setDate] = useState(undefined);
    const allContractOi = useTaifexOptionsOiResource(date);
    useEffect(() => {
        /** 處理 contractCode 選擇的邏輯，此時 preContractCode 非預設 undefined */
        if (contractCode && preContractCode && contractCode !== preContractCode) {
            const correspondDate = getContractDueDate(contractCode, dayAPI().add(1, 'day'));
            setDate(correspondDate);
        }
    }, [contractCode, allContractOi, date, preContractCode]);
    //處理當下時間無資料問題
    useEffect(() => {
        if (allContractOi?.length === 0 && date) {
            setDate(undefined);
        }
    }, [allContractOi, date]);
    const currentContractOi = allContractOi?.find(oi => oi.month === contractCode);
    return {
        state: {
            contractCode,
            currentContractOi,
        },
        acts: {},
    };
};
export const useOpenInterestState = createContainer(useOpenInterest);
